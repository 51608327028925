import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

import LayoutWhite from 'components/LayoutWhite/index'
import PortableText from '../components/portableText'

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { sanityPost } = data // data.markdownRemark holds our post data
  return (
    <LayoutWhite>
      <div className="container pt-4 pb-4">
        <h1 className="text-pink">{sanityPost.title}</h1>
        <h4 className="text-gray">{sanityPost.publishedAt}</h4>
        <div className="p-4">
          <img
            src={imageUrlFor(buildImageObj(sanityPost.mainImage))
              .width(1200)
              //.height(Math.floor((9 / 16) * 1200))
              //.fit('crop')
              .auto('format')
              .url()}
            alt={sanityPost.mainImage.alt}
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="blog-post-content">
                <PortableText blocks={sanityPost._rawBody} />
              </div>
              <div className="pt-2 pb-2">
                <Link to="/blog/">Go Back</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWhite>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    sanityPost(id: { eq: $id }) {
      title
      slug {
        current
      }
      publishedAt
      mainImage {
        ...SanityImage
        alt
      }
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`
